@import '../../styles/propertySets.css';

.root {
  /* Section border */
  border-top: 1px solid var(--matterColorNegative);
  border-bottom: 1px solid var(--matterColorNegative);

  margin: 48px auto;
}

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
}

.steps {
  display: grid;
  grid-template-columns: 1fr; /* One column */
  align-items: center;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr; /* Two columns */
  }

  @media (--viewportLarge) {
    grid-template-columns: 1fr 1fr 1fr 1fr; /* Four columns */
  }
}

.step,
.firstStep {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;

  /* Align text */
  text-align: left;
}

.firstStep {
  display: none;
  align-items: flex-start;
  text-align: left;

  @media (--viewportMedium) {
    display: flex;
  }
}

.step {
  align-items: center;

  /* Align text */
  text-align: center;

  @media (--viewportMedium) {
    height: 100%;
  }

  &:nth-child(2) {
    background-color: rgba(90, 165, 195, 0.05);
  }

  &:nth-child(3) {
    background-color: rgba(90, 165, 195, 0.1);
  }

  &:nth-child(4) {
    background-color: rgba(90, 165, 195, 0.15);
  }
}

.stepBigTitle {
  margin: 0;
}

.stepTitle {
  margin: 24px 0;
}

.stepText {
  margin: 0;
}
