@import '../../styles/propertySets.css';

.logoMobile {
  @apply --marketplaceFooterLogoTextMobile;

  /* Layout */
  display: flex;
  align-items: center;
}

.mobileIcon {
  height: 28px;
  margin-right: 8px;
}
