@import '../../styles/propertySets.css';

:root {
  --stripeColor: #635bff;
  --stripeColorDark: #4f48cc;
}

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.form {
  flex-grow: 1;
}

.title {
  /* Layout */
  display: flex;
  align-items: center;

  margin-bottom: 19px;
  color: var(--matterColor);

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 7px 0 7px 0;
  }
}

.description {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 24px;
  margin-bottom: 24px;
  max-width: 600px;

  /* Style */
  border: 2px solid #a19cff;
  background: #efeeff;
  border-radius: 4px;
}

.descriptionText {
  /* Font */
  font-weight: var(--fontWeightRegular);
  margin-top: 0;
  margin-bottom: 0;

  & a {
    color: var(--stripeColor);
  }
}

.stripeIcon {
  height: 34px;
  margin-left: 24px;
}

.stripeBox {
  user-select: none;

  @media (--viewportMedium) {
    max-width: 600px;
  }
}

.stripeBoxHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 24px;
  border: 2px solid var(--stripeColor);
  cursor: pointer;
}

.stripeBoxHeading {
  display: flex;
  align-items: center;
  margin: 0;
}

.stripeBoxContent {
  padding: 24px;

  border-left: 2px solid var(--stripeColor);
  border-right: 2px solid var(--stripeColor);
  border-bottom: 2px solid var(--stripeColor);
}

.stripeBoxChevron {
  height: 14px;
  width: 14px;

  /* Animation */
  transition: all 0.15s ease-in-out;
}

.stripeBoxChevronActive {
  transform: rotate(180deg);
}

.stripeBoxBadge {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightRegular);
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;
  margin-left: 12px;

  padding: 6px 8px;
  background-color: var(--matterColorNegative);
  border-radius: 4px;
}

.stripeBoxBadgeConnected {
  background-color: var(--successColor);
  color: var(--matterColorLight);
}

.payoutButtonContainer {
}

.payoutButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  background-color: var(--stripeColor);

  &:hover {
    background-color: var(--stripeColorDark);
  }

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: calc(96px - 12px);
  }
}
