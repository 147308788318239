@import '../../styles/propertySets.css';

.root {
  display: flex;
  align-items: center;
}

.tooltip {
  max-width: 300px;
}

.icon {
  margin-left: 8px;
}
