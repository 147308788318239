.root {
  width: 100%;
  position: sticky;
  top: 0;
  background-color: var(--matterColorLight);
  z-index: var(--zIndexTopbar);
}

.absoluteRoot {
  @media (--viewportMedium) {
    position: absolute;
    background-color: transparent;
  }
}
