@import '../../styles/propertySets.css';

/* Desktop */
.root {
  /* Layout */
  width: 100%;

  /* shadows */
  box-shadow: var(--boxShadowLight);

  /* Bg color */
  background-color: var(--matterColorLight);
}

.landingPageRoot {
  /* Set transparent bg */
  background-color: transparent;

  /* Remove shadow */
  box-shadow: none;

  & .signupLink,
  & .loginLink,
  & .inboxLink,
  & .miniSessionsLink,
  & .navigationLink {
    color: var(--matterColorLight);
  }

  & .navWrapper {
    /* Topbar width */
    max-width: 1480px;
    padding: 0 12px 0 24px;
  }
}

.navWrapper {
  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* Topbar width */
  width: 100%;

  /* Size and position */
  height: var(--topbarHeightDesktop);
  padding: 0 26px 0 36px;
  margin: 0 auto;
}

/* logo */
.logoLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;

  /* Logo layout */
  display: flex;
  align-items: center;
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 42px;
}

.landingPageLogo {
  height: 44px;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--matterColorNegative);

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
  color: var(--matterColor);
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH5FontStyles;
  text-transform: capitalize;

  padding: 10px;
  margin: 0 12px;

  /* Style */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  border-radius: 3px;

  /* Animation */
  transition: all var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColorDark);
    color: var(--matterColorLight);
  }
}

.createListing {
  @apply --TopbarDesktop_label;
}

/* Inbox */
.inboxWithNotification {
  display: flex;
}

.inboxLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH5FontStyles;
  height: 100%;
  padding: 0 12px 0 12px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColor);

  &:hover {
    color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.inbox {
  @apply --TopbarDesktop_label;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.navigationLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH5FontStyles;
  text-transform: capitalize;
  height: 100%;
  padding: 0 12px 0 12px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColor);

  &:hover {
    color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.navigationItem {
  @apply --TopbarDesktop_label;
  position: relative;
}

/* Profile menu */
.profileMenuLabel {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* Authentication */
.signupLink {
  @apply --TopbarDesktop_linkHover;
  text-transform: capitalize;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColor);

  @media (--viewportMedium) {
    display: none;
  }

  @media (--viewportLarge) {
    display: block;
  }

  &:hover {
    color: var(--marketplaceColor);
  }
}

.loginLink {
  @apply --TopbarDesktop_linkHover;
  text-transform: capitalize;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColor);

  &:hover {
    color: var(--marketplaceColor);
  }
}

.signup,
.login {
  @apply --marketplaceH5FontStyles;
  @apply --TopbarDesktop_label;

  display: initial;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.yourListingsLink {
  position: relative;
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH5FontStyles;
  font-size: 14px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}

.menuWrapper {
  display: flex;
  align-items: center;
}

.largeDeviceLink {
  display: none;

  @media (--viewportLarge) {
    display: initial;
  }
}
