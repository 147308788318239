@import '../../styles/propertySets.css';

.root {
}

.title {
  @apply --marketplaceH2FontStyles;

  margin-top: 0;
  margin-bottom: 0;
}
.images {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  align-items: center;
  gap: 24px;

  margin-top: 24px;

  @media (--viewportLarge) {
    display: grid;
    grid-template-columns: 1fr 1fr 0.5fr 1fr 1fr;
    margin-top: 48px;
  }
}

.image {
  width: 100%;
  object-fit: contain;
  filter: grayscale(70%);
  transition: var(--transitionStyleButton);

  &:nth-child(3) {
    height: 32px;
  }

  @media (--viewportMedium) {
    height: 42px;

    &:nth-child(4) {
      height: 32px;
    }

    &:nth-child(3) {
      height: 58px;
    }
  }
}
