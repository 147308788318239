.root {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
  margin-top: 48px;
}

.success {
  color: var(--successColor);
  margin: -24px 0 0;
}

.error {
  color: var(--failColor);
  margin: 24px 0 0;

  & a {
    color: #007fed;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 96px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    display: inline-block;
    width: 100%;
    margin-top: 36px;
    margin-bottom: 0;
  }
}
