@import '../../styles/propertySets.css';

.root {
  position: relative;

  /* Child layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: center;
    padding-right: 72px;
  }
}

.aspectWrapper {
  position: relative;
}

.image {
  height: 250px;
  width: 370px;
  object-fit: cover;
  border-radius: 3px;
}

.info {
  display: flex;
  flex-direction: column;
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 0;
    padding-left: 2rem;
  }
}

.heading {
  @apply --marketplaceBodyFontStyles;
  line-height: 1;
  color: var(--matterColor);

  /* Layout */
  display: flex;
  align-items: center;

  /* Position */
  margin: 18px 0;

  & span {
    font-weight: var(--fontWeightBold);

    /* Move down */
    position: relative;
    top: 2px;
  }
}

.stars {
  margin-right: 16px;
}

.star {
  height: 16px;
  width: 16px;
  margin-right: 4px;

  &:last-child {
    margin-right: 0;
  }
}

.text {
  /* Position */
  margin: 0;

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
}

.shootedBy {
  /* Layout */
  display: flex;
  align-items: center;

  /* Position */
  margin: 0;
}

.cameraIcon {
  margin-right: 16px;
  fill: var(--marketplaceColor);
}
