@import '../../styles/propertySets.css';

:root {
  --ActiveLine: {
    content: '';

    /* Line position */
    position: absolute;
    bottom: 0;
    left: 0;

    /* Line layout */
    height: 3px;
    width: 100%;
    background-color: var(--marketplaceColor);
  }
}

.root {
}

.header {
  /* Background */
  background: url('./images/hero.png');
  background-color: var(--marketplaceColor);
  background-size: 1300px 700px;
  background-repeat: repeat;

  /* Formatting */
  text-align: center;
  border-bottom: 1px solid #ebebeb;
  color: var(--matterColorLight);

  /* Layout */
  padding-top: 12vw;
  padding-bottom: 12vw;
  padding-left: 24px;
  padding-right: 24px;

  @media (--viewportMedium) {
    padding-top: 7vw;
    padding-bottom: 7vw;
    padding-left: 0;
    padding-right: 0;
  }

  /* Content */
  & h1 {
    line-height: 36px;
    margin: 0;
    text-align: center;

    @media (--viewportMedium) {
      line-height: 52px;
    }
  }

  & p {
    /* Make every paragraph add 
      same spacing at the top */
    margin: 34px auto 0;
    color: var(--matterColorLight);

    @media (--viewportMedium) {
      width: 550px;
    }

    & > p {
      display: none;
      width: auto;
      margin: 0;

      @media (--viewportMedium) {
        display: block;
      }
    }
  }
}

.headerLinks {
  /* Layout */
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  flex-shrink: 0;
  overflow-x: scroll;

  /* Formatting */
  text-align: center;
  border-bottom: 1px solid #ebebeb;

  /* Background */
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    justify-content: center;
    flex-shrink: unset;
    overflow-x: unset;
  }

  & li {
    padding: 18px 0;
    white-space: nowrap;
    margin-left: 24px;

    &:first-child {
      margin-left: 0;
      padding-left: 24px;
    }

    &:last-child {
      padding-right: 24px;
    }

    & a {
      position: relative;
      line-height: 20px;
      font-size: 17px;
      padding: 18px 0;
      cursor: pointer;
      text-decoration: none !important;
      color: var(--matterColor);

      &:hover {
        color: var(--marketplaceColor);

        &:after {
          @apply --ActiveLine;
        }
      }

      @media (--viewportMedium) {
        padding: 19px 24px;
        margin-left: 0;
      }
    }

    @media (--viewportMedium) {
      padding: 18px 0;
      margin-left: 0;
      white-space: unset;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  & .active {
    color: var(--marketplaceColor);

    &:after {
      @apply --ActiveLine;
    }
  }
}

.pageLink {
}
