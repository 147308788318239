@import '../../styles/propertySets.css';

.root {
  /* Child layout */
  display: flex;
  align-items: center;
  flex-direction: column;
}

.heading {
  @apply --marketplaceH2FontStyles;
  text-align: center;
  margin: 24px auto 12px;
  text-transform: capitalize;
}

.text {
  text-align: center;
  margin: 0 auto 12px;
}

.newsletter {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.field {
  margin: 0;
  width: 100%;

  & > input {
    padding: 12px 0;
  }
}

.success {
  color: var(--successColor);
  margin: 12px 0 0;
}

.error {
  color: var(--failColor);
  margin: 24px 0 0;

  & a {
    color: #007fed;
  }
}

.submitButtonWrapper {
  position: absolute;
  right: 0;
  width: 26px;
}

.submitButton {
  background: none !important;
  max-width: 26px;
  height: auto;
  min-height: auto;

  &:hover {
    background: none;
    box-shadow: none;
  }
}
