@import '../../styles/propertySets.css';

.root {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  @media (--viewportMedium) {
    grid-template-columns: 60% auto;
    grid-gap: 48px;
  }
}

.sessionsContentWrapper {
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.sessionsImageWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  grid-row: 1;

  @media (--viewportMedium) {
    grid-row: auto;
  }
}

.title {
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 24px;
}

.text {
  margin-top: 0;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.image {
  max-height: 300px;
  width: 100%;
  object-fit: cover;
  border-radius: 3px;

  @media (--viewportMedium) {
    max-height: 385px;
  }
}

.button {
  max-width: 250px;
  margin: 24px auto 0;
  text-transform: capitalize;

  @media (--viewportMedium) {
    margin: 34px 0 0;
  }
}
