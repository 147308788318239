@import '../../styles/propertySets.css';

.root {
}

.input {
  border-bottom-color: var(--attentionColor);

  /* &:read-only {
    border-bottom-color: var(--matterColorAnti);
    color: var(--matterColorAnti);
    cursor: default;
  } */
}

.inputSuccess {
  border-bottom-color: var(--marketplaceBlue);
}

.inputSuccessMarketplace {
  border-bottom-color: var(--marketplaceColor);
}

.inputInnerHovered {
  border-bottom-color: var(--matterColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}

.innerContainer {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  position: relative;
  width: 100%;
}

.innerText {
  @apply --marketplaceInputWithInnerStyles;
  font-size: 17px;
  line-height: 24px;
  white-space: nowrap;
}

.tooltipLabel {
  display: flex;
  align-items: center;
}

.copyToClipboard {
  position: relative;
  display: flex;
  align-items: center;
}

.copy {
  display: flex;
  align-items: center;
  position: absolute;
  right: 5px;
  cursor: pointer;

  & svg {
    height: 16px;
    width: 16px;
  }

  &:hover {
    & svg {
      color: var(--marketplaceColor);
    }
  }
}

.copyTooltip {
  line-height: 1.8;
}

.infoTooltip {
  max-width: 300px;
  margin-left: 8px;
  line-height: 1.8;

  position: relative;
  top: -1px;
}

.infoText {
  margin-top: 0;
  margin-bottom: 6px;

  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}
