@import '../../styles/propertySets.css';

.root {
  height: 26px;
  width: 26px;

  & path {
    fill: var(--marketplaceColor);
    transition: fill 0.15s ease-in-out;
  }

  &:hover {
    & path {
      fill: var(--marketplaceColorDark);
    }
  }
}
