@import '../../styles/propertySets.css';

.root {
}

.content {
  display: flex;
  flex-direction: row;
}

.avatar {
  flex-shrink: 0;
  margin-right: 36px;

  @media (--viewportMedium) {
    /* Size */
    width: 72px;
    height: 72px;
  }
}

.info {
  flex: 1;
  margin-top: 5px;
}

.heading {
  @apply --marketplaceH3FontStyles;
  margin: 0 0 6px;

  @media (--viewportMedium) {
    font-size: 17px;
  }
}

.link {
  white-space: nowrap;
  color: var(--matterColorLink);
  font-weight: var(--fontWeightRegular);
}

.mobileBio,
.desktopBio {
  @apply --marketplaceBodyFontStyles;
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 0;
  transition: all 1s ease-in;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.mobileBio {
  @media (--viewportMedium) {
    display: none;
  }
}

.desktopBio {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.showMore {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceBodyFontStyles;
  color: var(--matterColorLink);
  margin: 0 0 0 5px;

  /* Remove */
  display: none;
}

.links {
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightRegular);
  margin-top: 6px;

  @media (--viewportMedium) {
    margin: 6px 0 0 0;
  }
}

.withBioMissingAbove {
  @media (--viewportMedium) {
    /* Fix baseline alignment if bio is missing from above */
    margin-top: 16px;
  }
}

.linkSeparator {
  margin: 0 8px;
  color: var(--matterColorAnti);
}

.headingRow {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

.editProfile {
  @apply --marketplaceSmallFontStyles;
  margin: 0;
  margin-bottom: 0;
  white-space: nowrap;
}

.contact {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceSmallFontStyles;
  color: var(--matterColorLink);
  font-weight: var(--fontWeightRegular);
  margin: 0;
}
