@import '../../styles/propertySets.css';

.root {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  font-size: 12px;
  color: var(--matterColorLight);
  text-align: center;

  background-color: var(--failColor);

  /* Layout of a red dot */
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  width: 22px;
  height: 22px;
  min-width: 22px;
  border-radius: 50%;
  padding-right: 5px;
  padding-left: 5px;
  margin-left: 8px;

  /* Font margins off */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;

    position: relative;
  }
}
