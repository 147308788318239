@import '../../styles/propertySets.css';

.root {
  margin: 0;
}

.reviewItem {
  /* Spacing */
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--matterColorNegative);

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.review {
  display: flex;
  flex-direction: row;
  flex-basis: 564px;
  flex-shrink: 1;
  flex-grow: 0;
}

.avatar {
  flex-shrink: 0;
  margin-right: 22px;
}

.reviewContent {
  @apply --marketplaceBodyFontStyles;
  margin: 0;
  white-space: pre-line;
}

.reviewInfo {
  @apply --marketplaceH5FontStyles;
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin-top: 9px;
  }
}

.separator {
  margin: 0 7px;
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.desktopReviewRatingWrapper {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  position: relative;
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  white-space: nowrap;
}

.reviewRatingStar {
  height: 14px;
  width: 14px;
  margin-right: 4px;

  &:last-of-type {
    margin-right: 0;
  }
}

.reviewDisplayNameContainer {
  margin: 0px 0 9px;
  line-height: 1;
}

.reviewDisplayName {
  @apply --marketplaceH3FontStyles;
  line-height: 1;
  margin: 0;

  @media (--viewportMedium) {
    font-size: 17px;
  }
}

.reviewImageContainer {
  height: 180px;
  width: 180px;

  border-radius: 12px;
  background-color: var(--marketplaceColor);
  border: 6px solid var(--marketplaceColor);

  margin-top: 12px;
  cursor: pointer;
}

.reviewImage {
  height: 100%;
  width: 100%;

  object-fit: cover;
  border-radius: 8px;
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.imageRoot {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 0;

  @media (--viewportMedium) {
    padding: 100px 10vw;
  }
}

.imageRootWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;

  height: 100%;
  width: 80%;
  margin: 0 auto;

  & img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  @media (--viewportMedium) {
    width: 100%;

    & img {
      height: unset;
      width: unset;
    }
  }
}
