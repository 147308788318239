.root {
}

.select {
  color: var(--matterColorAnti);
  border-bottom-color: var(--attentionColor);
  padding-right: 20px;

  & > option {
    color: var(--matterColor);
  }
  & > option:disabled {
    color: var(--matterColorAnti);
  }

  &:disabled {
    border-bottom-color: var(--matterColorAnti);
    color: var(--matterColorAnti);
    cursor: default;
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-bottom-color: var(--marketplaceBlue);
}

.selectError {
  border-bottom-color: var(--failColor);
}
