@import '../../styles/propertySets.css';

.root {
  margin-top: 24px;
}

.title {
  @apply --marketplaceH2FontStyles;

  margin-top: 0;
  margin-bottom: 0;
}

.reviews {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 33px;
  }
}
