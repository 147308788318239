.root {
  display: flex;
  flex-direction: column;
}

.listingCards {
  /* Child layout */
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  /* Spacing */
  padding: 0 24px 96px 24px;

  @media (--viewportMedium) {
    margin-top: 20px;
    grid-gap: 36px 24px;
  }

  @media (--viewportLarge) {
    padding: 0 36px 96px 36px;

    /* On large size use two columns */
    grid-template-columns: repeat(2, 1fr);
  }
}

.miniSessionCards {
  /* Child layout */
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0 24px;

  /* Spacing */
  padding: 0 24px 96px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 96px 36px;
  }
}

.listingCardsWithoutMap {
  /* The grid column count will be different if the map isn't visible */
  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  }

  & a {
    &:first-child {
      padding-top: 0;
    }
  }
}

.miniSessionCardsWithoutMap {
  /* The grid column count will be different if the map isn't visible */
  @media (--viewportMedium) {
    grid-template-columns: 1fr;
    grid-gap: 0 48px;
  }
}

.listingCard {
}

.miniSessionCard {
  margin-bottom: 36px;
  margin-bottom: 0;
  padding: 20px 0;

  @media (--viewportMedium) {
    flex-basis: 100%;
    flex-direction: row-reverse;
    flex-basis: calc(100%);
  }

  @media (--viewportLarge) {
    flex-basis: calc(100%);
    margin-bottom: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
    border-bottom: 0;
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: var(--matterColorLight);
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}
