@import '../../styles/propertySets.css';

.root {
  /* Child layout */
  display: flex;
  align-items: center;
  flex-direction: column;

  /* Remove padding */
  padding: 0;

  @media (--viewportMedium) {
    /* Increase padding */
    padding: 38px 0;
  }
}

.title,
.text {
  text-align: center;
  margin: 0 auto;
}

.title {
  @apply --marketplaceH2FontStyles;
  max-width: 600px;

  /* Margin */
  margin-top: 48px;
  margin-bottom: 0;
}

.text {
  margin-top: 24px;
  max-width: 650px;
}

.icon {
  height: 80px;
  width: 80px;

  @media (--viewportMedium) {
    /* Increase the icon */
    height: 100px;
    width: 100px;
  }

  @media (--viewportLarge) {
    /* Increase the icon */
    height: 108px;
    width: 108px;
  }
}
