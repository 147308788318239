@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: grid;
  grid-template-columns: 2fr 1fr;
  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportMedium) {
    display: flex;
    grid-template: initial;
  }

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;

    & .threeToTwoWrapper {
      transform: scale(1.03);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
  order: 2;

  @media (--viewportMedium) {
    min-height: 112px;
    width: 180px;
    order: initial;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  background: var(--matterColorNegative); /* Loading BG color */

  @media (--viewportMedium) {
    padding-bottom: 0;
    width: 180px;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0;
  width: 100%;

  @media (--viewportLarge) {
    padding: 0;
  }
}

.date {
  display: none;

  @media (--viewportMedium) {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    margin-right: 18px;
    text-align: center;
  }
}

.day {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 3px;
  }
}

.month {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-transform: uppercase;
  font-size: 12px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.length,
.location,
.mobileLocation,
.files,
.time {
  color: var(--matterColorAnti);
  text-transform: capitalize;
}

.location {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.mobileLocation {
  @media (--viewportMedium) {
    display: none;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;

  margin-right: 18px;
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.titleWrapper {
  display: flex;
  align-items: center;

  margin-top: 0;
  margin-bottom: 10px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 3px;
  }
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);
  font-size: 19px;
  margin: 0;
}

.instantBook {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  text-transform: capitalize;
  font-size: 12px; /* Make font size even smaller */
  line-height: 1;
  white-space: nowrap;
  font-weight: var(--fontWeightRegular);
  color: var(--matterColorLight);
  text-transform: capitalize;

  /* Layout */
  display: none;

  /* Style */
  background-color: var(--marketplaceBlue);
  border-radius: 3px;
  padding: 5px;
  margin-left: 12px;
  margin-right: 12px;

  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.authorInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorGray);
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.006em;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-top: 0;

    & span {
      &:nth-child(2) {
        margin-top: 3px;
      }
    }
  }

  & span + span {
    text-transform: capitalize;

    &:before {
      content: '•';
      margin: 0 5px;
      position: relative;
      bottom: 1px;
      font-size: 10px;
    }
  }
}

.listingInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: #393939;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.006em;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.postedBy {
  &:before {
    content: '•';
    margin: 0 5px;
    position: relative;
    bottom: 1px;
    font-size: 10px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.authorName {
  color: var(--marketplaceColor);
  font-weight: bold;
}

.bottomCard {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.reviews {
  margin-top: 11px;
  line-height: 20px;

  & svg {
    height: 15px;
    width: 15px;
    margin-right: 5px;
  }

  & span {
    font-size: 12px;
    color: var(--matterColorGray);
    font-weight: normal;
    letter-spacing: 0.04em;
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.mobileDate {
  font-weight: var(--fontWeightSemiBold);

  &:after {
    content: '•';
    margin: 0 5px;
    position: relative;
    bottom: 1px;
    font-size: 10px;

    @media (--viewportMedium) {
      display: none;
    }
  }

  @media (--viewportMedium) {
    display: none;
  }
}

.bio {
  margin-top: 0;
  margin-bottom: 12px;
}

.description {
  color: #393939;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.006em;
  margin: 0;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;

  @media (--viewportMedium) {
    max-width: 50%;
  }
}
