.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--marketplaceBlue);
}

.inputError {
  border-bottom-color: var(--failColor);
}
