@import '../../styles/propertySets.css';

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 48px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.pageTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Change default font styles */
  color: var(--matterColor);
  font-size: 20px;
  line-height: 32px;

  /* Remove margin */
  margin: 0;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentSide {
  margin-bottom: 48px;

  @media (--viewportMedium) {
    /* Align always on top */
    position: sticky;
    top: 110px;

    /* Layout */
    width: 193px;
    margin-right: 103px;
    margin-top: 4px;
    margin-bottom: 0;
  }

  & p {
    color: var(--matterColorLightBlue);
    font-size: 17px;
    margin: 0;
  }
}

.contentSideTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);
  margin: 0;
}

.contentSideList {
  margin: 24px 0 0;

  & li {
    @apply --marketplaceSmallFontStyles;
    font-size: 15px;
    margin: 0 0 12px;

    &:hover {
      & .contentSideLink {
        color: var(--marketplaceColor);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.contentSideLink {
  color: var(--matterColor);
}

.contentMain {
  width: 90%;

  @media (--viewportMedium) {
    max-width: 650px;
  }

  & p {
    margin: 16px 0;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media (--viewportMedium) {
      &:last-of-type {
        margin-bottom: 32px;
      }
    }
  }

  & ul {
    list-style: initial;
    padding-left: 24px;
    margin: 24px 0 0;

    @media (--viewportMedium) {
      padding-left: 48px;
    }

    & li {
      @apply --marketplaceBodyFontStyles;
      margin: 0 0 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  & img {
    width: 100%;
    object-fit: cover;
  }

  & .withoutMargin {
    margin-bottom: 16px;
  }
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  margin: 0;
}

.count {
  color: var(--marketplaceColor);
}

.quote {
  display: flex;
  justify-content: center;
  margin: 16px 0 32px;

  & p {
    margin: 0 !important;
  }

  &:first-child {
    & p {
      &:before {
        content: '“';
      }
    }
  }

  &:nth-child(2) {
    margin: 32px 0 16px;

    & p {
      &:after {
        content: '”';
      }
    }
  }
}

.quoteMark {
  min-height: 100%;
  min-width: 5px;
  width: 5px;
  background-color: var(--marketplaceColor);
  margin-right: 15px;
}

.founder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px 0 32px;

  & p {
    margin: 0;

    &:first-child {
      margin-bottom: 5px;
    }

    @media (--viewportMedium) {
      &:first-child {
        margin-bottom: 0;
      }
    }
  }

  @media (--viewportMedium) {
    align-items: flex-end;
    margin: 0;
  }
}

.marketplace {
  color: var(--marketplaceColor);
}

.activeLink {
  color: var(--marketplaceColor);
}

.imageContainer {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 32px;
}

.imageWithCaption {
  border-radius: 2px;
  border: 2px solid var(--matterColorNegative);
  padding: 12px;

  & img {
    border-radius: 2px;
  }
}

.imageCaption {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  font-style: italic;

  /* Layout */
  margin: 6px 0 0;
  align-self: center;
}

.tipsImage {
  border-radius: 2px;
  margin-top: 24px;
}
