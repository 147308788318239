@import '../../styles/propertySets.css';

.root {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }
}

.accordionItem {
  padding: 10px 12px;
  background-color: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
  border-radius: 8px;
  align-self: start;
  cursor: pointer;
}

.header {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Font */
  @apply --marketplaceBodyFontStyles;
  margin: 0;
}

.headerWrapper {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  margin-right: 12px;
}

.content {
  position: relative;
  height: 0px;
  overflow: hidden;

  /* Animation */
  transition: height 0.3s ease-in-out;
}

.contentDescription {
  padding-top: 12px;
}

.contentExpanded {
  height: 36px;

  @media (--viewportMedium) {
    height: 44px;
  }
}

.arrow {
  /* Animation */
  transition: transform 0.3s ease-in-out;
}

.arrowExpanded {
  transform: rotate(180deg);
}
