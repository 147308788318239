@import '../../styles/propertySets.css';

.root {
}

/* LANDING PAGE STYLES */
.landingPageRoot {
  & .chevronRightContainer {
    /* Remove on mobile */
    display: none !important;

    /* Position */
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    z-index: 1;
    cursor: pointer;

    /* Styles */
    /* background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), var(--matterColorLight)); */

    @media (--viewportMedium) {
      display: flex !important;
      width: 72px;
    }
  }

  & .chevronLeftContainer {
    /* As we are using carousel just on homepage
     we will remove the left chevron from both
     desktop and mobile layout */
    display: none !important;

    /* Position */
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    z-index: 1;
    cursor: pointer;

    /* Styles */
    background: linear-gradient(90deg, var(--matterColorLight), hsla(0, 0%, 100%, 0.01));

    @media (--viewportMedium) {
      width: 72px;
    }
  }

  & .chevron {
    /* Position */
    position: absolute;
    right: 0;
    left: auto;

    /* Size */
    width: 20px;
    top: 50%;
    z-index: 20;
    transform: translate(0, -50%);
  }

  & .chevronLeft {
    right: auto;
    left: 0;

    & svg {
      transform: rotate(180deg);
    }
  }

  & .chevronIcon {
    height: 26px;
    width: 26px;
  }
}

/* LISTING PAGE STYLES */
.listingPageRoot {
  & .chevronRightContainer {
    /* Remove on mobile */
    display: flex;

    /* Position */
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 52px;
    z-index: 1;
    cursor: pointer;

    @media (--viewportMedium) {
      width: 96px;
    }
  }

  & .chevronLeftContainer {
    /* Position */
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 52px;
    z-index: 1;
    cursor: pointer;

    @media (--viewportMedium) {
      width: 104px;
    }
  }

  & .chevronRightContainer,
  & .chevronLeftContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    /* Activate icon when hovering it's container */
    &:hover {
      & .chevron {
        opacity: 1;
      }
    }
  }

  & .chevronRightContainer {
    & .chevron .chevronIcon {
      margin-left: 4px;
    }
  }

  & .chevron {
    /* Layout */
    display: flex;
    justify-content: center;
    align-items: center;

    /* Size */
    height: 52px;
    width: 52px;

    /* Style */
    background-color: var(--marketplaceColor);
    opacity: 0.8;

    @media (--viewportMedium) {
      border-radius: 50%;
    }
  }

  & .chevronLeft {
    & .chevronIcon {
      transform: rotate(180deg);
      margin-right: 4px;
    }
  }

  & .chevronIcon {
    height: 24px;
    width: 24px;

    /* Fill */
    stroke: var(--matterColorLight);
    fill: var(--matterColorLight);
  }
}
