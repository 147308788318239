@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
  @apply --marketplaceModalIconStyles;
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
  text-transform: uppercase;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
  margin-top: 12px;
}

.modalImage {
  margin: 24px auto 0;

  /* Size */
  height: 270px;
  width: 100%;

  /* Background */
  background-image: linear-gradient(-45deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)),
    url('./main.jpg');
  background-size: cover;

  /* Style */
  border-radius: 2px;

  @media (--viewportMedium) {
    height: 300px;
  }
}
