@import '../../styles/propertySets.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;

  @media (--viewportLarge) {
    padding: 88px 36px 82px 59px;
    border-left: 1px solid var(--matterColorNegative);
    background-color: var(--matterColorLight);
  }

  @media (--viewportLargeWithPaddings) {
    padding: 88px calc((100% - 1056px) / 2) 82px 59px;
  }
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 19px;

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }
}
