@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

.listingCardBadge {
  text-transform: capitalize;

  &:before {
    /* Position */
    position: absolute;
    top: 2.5rem;
    right: -0.5rem;

    /* Content */
    content: '';

    /* Layout */
    background: var(--marketplaceColor);
    height: 28px;
    width: 28px;
    transform: rotate(45deg);
  }

  &:after {
    /* Position */
    position: absolute;
    top: 20px;
    right: -14px;

    /* Content */
    content: attr(data-label);
    text-align: center;

    /* Layout */
    padding: 0.5rem;
    width: 9rem;
    background: var(--marketplaceColor);
    color: var(--matterColorLight);
    box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
    border-radius: 2px;
    border-bottom-right-radius: 0px;

    /* Font */
    @apply --marketplaceSmallFontStyles;
    line-height: 18px;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  height: 290px;
  background: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  height: 100%;
  width: 100%;
  border-radius: var(--borderRadius);
  object-fit: contain;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 16px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  text-align: center;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 3px;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.certificateInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.description {
  margin-top: 6px;
}

.descriptionText {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightRegular);
  margin: 0;
}

.userInfo {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.avatar {
  height: 34px;
  width: 34px;
}

.avatarBorder {
  border: 2px solid var(--marketplaceColor);
}

.displayName {
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightRegular);
  color: var(--matterColor);

  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 10px;
}

.userName {
  font-weight: var(--fontWeightSemiBold);
}

.listingLocation {
  display: flex;
  align-items: center;
}

.location {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightRegular);
  margin: 0;
}

.locationIcon {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.reviews {
  display: flex;
  align-items: center;
  gap: 6px;
}

.reviewStars {
  display: flex;
  align-items: center;
  gap: 2px;
  margin-top: -2px;

  & svg {
    height: 16px;
    width: 16px;

    @media (max-width: 768px) {
      &:not(:last-child) {
        display: none;
      }
    }
  }
}

.locationAndReviews {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.divider {
  color: var(--matterColorAnti);
  margin: 0 6px;
}

.averageReview {
  font-size: 14px;
  line-height: 24px;
  color: var(--matterColorAnti);
}
