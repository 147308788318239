@import '../../styles/propertySets.css';

.root {
}

.title {
  @apply --marketplaceH2FontStyles;

  margin-top: 0;
  margin-bottom: 0;
}

.carousel {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 33px;
  }
}

.location {
  width: 100%;
  box-shadow: var(--boxShadowSectionLocation);
  border-radius: 5px;

  @media (--viewportLarge) {
    margin-right: 24px;
  }

  /* Remove link's hover effect */
  &:hover {
    transform: scale(1.01);
    box-shadow: var(--boxShadowSectionLocationHover);
    text-decoration: none;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: var(--transitionStyleButton);
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  object-fit: cover;
}

.linkText {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  /* Layout */
  padding: 20px;
  margin: 0;

  /* Border */
  border-left: 1px solid var(--matterColorNegative);
  border-right: 1px solid var(--matterColorNegative);
  border-bottom: 1px solid var(--matterColorNegative);

  /* Add radius at bottom borders */
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
