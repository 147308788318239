@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.img {
  object-fit: cover;
  object-position: center;
  display: none;
}

.heroContent {
  /* Size */
  max-width: 1128px;
  width: 100%;

  /* Padding and margin */
  margin: 0 auto;
  padding: 48px 24px 72px;

  /* Position */
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;

  /* Font */
  text-align: center;

  @media (--viewportMedium) {
    padding: 48px 24px;
    margin-top: -24px;
    top: 50%;
    bottom: unset;
    transform: translate(-50%, -50%);
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);

  composes: animation;
  animation-delay: 0.5s;
  margin-top: 0;
}

.heroSubTitle {
  @apply --marketplaceHeroSubTitleFontStyles;
  color: var(--matterColorLight);

  composes: animation;
  animation-delay: 0.65s;
  margin-top: 0;
}

/* 
.heroButton {
  @apply --marketplaceButtonStyles;
  composes: animation;

  animation-delay: 0.8s;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
    margin: 0 auto;
  }
} */

/* Search */
.search {
  max-width: 670px;
  margin: 34px auto 0;

  composes: animation;
  animation-delay: 0.8s;

  @media (--viewportLarge) {
    max-width: 740px;
    margin: 48px auto 0;
  }
}

.searchLink {
  /* Flex layout */
  flex-grow: 1;

  /* Style */
  background-color: var(--matterColorLight);
  border-radius: 3px;

  /* Size */
  height: 100%;

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.arrowIconContainer {
  /* Remove on mobile */
  display: none;

  /* Position */
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -24px);

  cursor: pointer;

  /* Animation */
  transition: 0.25s all ease-in-out;

  @media (--viewportMedium) {
    display: flex;
  }

  &:hover {
    /* Move the icon a bit upper */
    bottom: 5px;

    & .arrowIcon {
      /* On hover change the icon color */
      stroke: var(--matterColorLight);
      fill: var(--matterColorLight);
    }
  }
}

.arrowIcon {
  height: 34px;
  width: 34px;

  /* Colors */
  stroke: var(--matterColorAnti);
  fill: var(--matterColorAnti);
}

.carouselItemContainer {
  position: relative;
  height: calc(100vh - var(--topbarHeight));

  @media (--viewportMedium) {
    height: 100vh;
  }
}

.carouselItem {
  background-size: cover;
  background-position: center center;
  height: 100%;
}

.photographer {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  line-height: 100%;
  color: var(--matterColorNegative);

  /* Position */
  position: absolute;
  bottom: 24px;
  right: 24px;
  margin: 0;

  /* Style */
  background-color: rgba(241, 155, 140, 0.5);
  border-radius: 2px;
  padding: 8px 12px;
  opacity: 0.9;

  @media (--viewportMedium) {
    bottom: 29px;
  }
}

.photographerName {
  font-weight: var(--fontWeightSemiBold);
}
