@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
  @apply --marketplaceModalIconStyles;
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

.modalInput {
  margin: 24px 0;

  &:last-of-type {
    margin-bottom: 48px;
  }
}

.modalButton {
  /* Styles */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  &:hover {
    background-color: var(--marketplaceColorLight);
  }

  &:focus {
    color: var(--matterColor);

    & > span {
      color: var(--matterColorLight);
    }
  }
}

.search {
  position: relative;
  margin: 24px 0 48px;
}

.autoSuggestContainer {
  display: block;
  background: var(--marketplaceColor);
}

.autoSuggestContainerOpen {
  display: block;
  position: absolute;
  width: 100%;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  left: 0;
  max-height: 350px;
  overflow-y: auto;
}

.autoSuggestList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  padding: 17px 0;
}

.autoSuggestInput {
}

.autoSuggest {
  @apply --marketplaceBodyFontStyles;
  cursor: pointer;
  padding: 10px 34px;
  color: var(--matterColorLightTransparent);
  line-height: 24px;
  transition: var(--transitionStyleButton);
  margin: 0;

  &:hover {
    cursor: pointer;
    border-left: 6px solid var(--matterColorLight);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    color: var(--matterColorLight);
  }
}

.filterSearch {
  margin: 1px 0 13px;
}
