@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalBaseStyles;

  @media (--viewportMedium) {
    box-shadow: var(--boxShadowPopup);
  }
}

.form {
  @apply --marketplaceModalFormRootStyles;
}

.title {
  @apply --marketplaceModalTitleStyles;
}

.subTitle {
  margin: 12px 0 0;
}

.field {
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 48px;
  }
}

.error {
  /* Font */
  text-align: center;
  color: var(--failColor);

  /* Layout */
  width: 100%;
  margin: 12px 0 0;
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

/* 
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

.stripeElement {
  @apply --marketplaceInputStyles;
  @apply --marketplaceDefaultFontStyles;
}
