.root {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
  margin-top: 24px;
}

.error {
  color: var(--failColor);
}

.errorPlaceholder {
  @media (--viewportMedium) {
    width: 100%;
    height: 32px;
  }
}

.reviewRating {
  margin-top: 0;
  margin-bottom: 0;
}

.fileUpload {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.reviewContent {
  flex-shrink: 0;
  margin-top: 24px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.submitButton {
  margin-top: 48px;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    display: inline-block;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }
}
