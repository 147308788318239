@import '../../styles/propertySets.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;

  @media (--viewportLarge) {
    padding: 88px 36px 82px 59px;
    border-left: 1px solid var(--matterColorNegative);
    background-color: var(--matterColorLight);
  }

  @media (--viewportLargeWithPaddings) {
    padding: 88px calc((100% - 1056px) / 2) 82px 59px;
  }
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 12px;

  @media (--viewportLarge) {
    padding: 1px 0 7px 0;
  }
}

.subTitle {
  margin: 0 0 24px;
}

.dividerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0;
  text-transform: uppercase;
}

.error {
  @apply --marketplaceH4FontStyles;
  margin-top: 6px;
  margin-bottom: 12px;
  color: var(--failColor);
  overflow: hidden;

  @media (--viewportMedium) {
    margin-top: 6px;
    margin-bottom: 10px;
  }
}

.couponButton {
  margin-top: 48px;
}

.payButton {
  margin-bottom: 24px;
}
